const base = {
  [LANG.NL]: {
    yourChoice: `Je keuze`,
    yourSubscription: `Je abonnement`,
    whatDoYouGet: `Wat krijg je?`,
    understood: 'Oké begrepen',
    whatDoIGet: 'Wat krijg ik?',
    proceedToCheckout: 'Verder naar bestellen',
    multibrandText: (
      <>
        Gratis <span style={{ fontWeight: 'bold' }}>alle plusartikels</span>
      </>
    ),
    routeYouText: (
      <>
        Gratis <span style={{ fontWeight: 'bold' }}>fiets- en wandelapp</span>
      </>
    ),
    multibrandTextMobile: (
      <>
        Gratis <span style={{ fontWeight: 'bold' }}>alle plusartikels</span>
      </>
    ),
    routeYouTextMobile: (
      <>
        Gratis <span style={{ fontWeight: 'bold' }}>fiets- en wandelapp</span>
      </>
    ),
  },
  [LANG.DE]: {
    yourChoice: 'Ihre Wahl',
    yourSubscription: 'Ihr Abonnement',
    whatDoYouGet: 'Was bekommen Sie?',
    understood: 'Okay verstanden',
    whatDoIGet: 'Was bekomme Sie?',
    proceedToCheckout: 'Weiter zur Bestellung',
    multibrandText: '',
    routeYouText: '',
    multibrandTextMobile: '',
    routeYouTextMobile: '',
  },
  [LANG.EN]: {
    yourChoice: 'Your choice',
    yourSubscription: 'Your subscription',
    whatDoYouGet: 'What do you get?',
    understood: 'I understand',
    whatDoIGet: 'What do I get?',
    proceedToCheckout: 'Continue to order',
    multibrandText: '',
    routeYouText: '',
    multibrandTextMobile: '',
    routeYouTextMobile: '',
  },
  [LANG.FR]: {
    yourChoice: 'Votre choix',
    yourSubscription: 'Votre abonnement',
    whatDoYouGet: "Qu'est-ce que vous recevez?",
    understood: 'Je comprends',
    whatDoIGet: "Qu'est-ce que je reçois?",
    proceedToCheckout: 'Continuer à commander',
    multibrandText: '',
    routeYouText: '',
    multibrandTextMobile: '',
    routeYouTextMobile: '',
  },
  [LANG.PT]: {
    yourChoice: 'A sua escolha',
    yourSubscription: 'A sua subscrição',
    whatDoYouGet: 'O que recebe',
    understood: 'Ok entendo',
    whatDoIGet: 'O que é que recebo?',
    proceedToCheckout: 'Proceder para a encomenda',
    multibrandText: '',
    routeYouText: '',
    multibrandTextMobile: '',
    routeYouTextMobile: '',
  },
};

export default base;
